import Script from 'next/script';
import { createPulseTags as createTags } from '@borealis/pulse-common';

import type { ResponseWithLocals } from 'shared/types';

const isTest = () => (process.env.CYPRESS_MODE ?? '') === 'true';

// biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
export const createPulseTags = (applicationName: string, config: any, res: ResponseWithLocals | undefined) => {
    if (!isTest()) {
        const { loginId, spidId } = res?.locals ?? {}; // res.locals is not available on pages not loading credentials, like 404

        const { meta, script } = createTags({
            env: config.get('env'),
            brandName: config.get('brand'),
            applicationName,
            loginId,
            spidId,
            pulseClientVersion: undefined,
            pulseInitVersion: undefined,
        });

        return {
            pulse: {
                metaTags: meta,
                scriptTags: script,
            },
        };
    }
    return {
        pulse: {
            metaTags: [],
            scriptTags: [],
        },
    };
};

const tagsCommon = (tags) => tags.map((tag) => <script key={tag} dangerouslySetInnerHTML={{ __html: `</script>${tag}<script>` }} />);

export const Pulse = {
    HeadTags: ({ pulseMetaTags }) => tagsCommon(pulseMetaTags),
    ScriptTags: ({ pulseScriptTags }) => tagsCommon(pulseScriptTags),
};

export const PulseToGtmSync = () => (
    <>
        <Script
            id="gtm-init"
            dangerouslySetInnerHTML={{
                __html: `
                    (function(w,l){
                      w[l]=w[l]||[];
                      w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
                    })(window,'dataLayer');
                `,
            }}
        />
        <Script id="gtm" src="https://www.googletagmanager.com/gtm.js?id=GTM-TP67TQBT" />
        <Script id="pulse-to-gtm" src="https://assets.finn.no/pkg/pulse-event-listener/v1/index.js" />
    </>
);
